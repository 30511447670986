import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Col, Container, Row } from "react-bootstrap";
import sparkhomes from "../content/home/sparkhomes.png";
import Section from "../components/Section";
import ContactForm from "../components/ContactForm";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      comment: "",
      formErrors: { email: "", name: "", comment: "" },
      emailValid: false,
      nameValid: false,
      commentValid: false,
      formValid: false
    };
  }
  onSubmit = e => {
    console.log("submiting");
    var email = this.state.email;
    this.setState({
      nameValid: true
    });
    console.log("email " + email);
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    return (
      <div class="container">
        <div className="section">
          <div
            className="section-content"
            class="text-center"
            style={{ margin: "20px 0 20px 0" }}
          >
            <Container>
              <Row>
                <Col>
                  <Circ section="who">
                    <p>
                      Who We
                      <br />
                      Are
                    </p>
                  </Circ>
                </Col>
                <Col>
                  <Circ section="others">
                    <p>
                      Other
                      <br />
                      Sites
                    </p>
                  </Circ>
                </Col>
                <Col>
                  <Circ section="privacy">
                    <p>
                      Privacy
                      <br />
                      Policy
                    </p>
                  </Circ>
                </Col>
                <Col>
                  <Circ section="contact">
                    <p>
                      Contact
                      <br />
                      Us
                    </p>
                  </Circ>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Section
          id="who"
          title="Who we are"
          subTit="On a mission to make ideas and tools come to life!"
        >
          <Col md={6} xs={12}>
            <p>
              In our world, money isn't everything. We tackle problems that are
              unique and impactful. We make apps and provide services for
              enthusiasts by enthusiasts. We take great pride in our work and
              strive to make the best quality in all that we do.
            </p>
          </Col>
          <Col md={6} xs={12}>
            <p>
              While our team is small, we are big on ideas. We were founded by a
              group of colleagues, all with a passion to make something great.
              It takes time to make the things go right Most of our initial team
              were all at the time undergraduate students at the New Jersey
              Institute of Technology. However, our team is actively growing
              based on the success of our achievements.
            </p>
          </Col>
        </Section>

        <Section id="others" title="Our other sites">
          <Col md={6} xs={12}>
            <div style={{ backgroundColor: "white" }}>
              <a href="https://www.sparkhomesnj.com/">
                <img src={sparkhomes} width="100%" alt="UVGrade" />
              </a>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <br />
            <p>
              Looking for IT Consulting or WiFi needs? check out our consulting
              site. We provide powerful Wireless Networking options for
              residental and business customers at an incredible value, all
              thanks to Ubiquiti Unifi products.
            </p>
            <a href="http://www.sparkhomesnj.com/">
              http://www.sparkhomesnj.com/
            </a>
            <p>Servicing Central New Jersey.</p>
          </Col>
        </Section>

        <Section id="privacy" title="Privacy is everything to us">
          <Col md={6} xs={12}>
            <p>
              Privacy is a pretty big deal around here. We usually evaluate
              privacy on an App by App basis.
            </p>
            <p>
              Our general policy is that we try our very best to protect the
              privacy of our customers as best as possible. We try to collect
              the least amount of information about our customers as possible.
            </p>
            <p>
              As always, none of our Products or Services are intended to
              access other parts of your devices without explicit permission
              from the user. Our products and services are built with security
              "Best Practices" as we take security very seriously. However, if
              any of our Products or Services are compromised or not functional,
              we will not be held liable.
            </p>
          </Col>
          <Col md={6} xs={12}>
            <p>
              None of our Products or Services at the moment are sharing your
              app data with marketers or any 3rd party vendors. We reserve the
              right to change this in the future depending on the circumstances,
              in which we will update our terms of use in which we will require
              reacceptance for continued use. However, we have no interest right
              now in sharing any information with anyone. We like privacy as
              much as the rest of the world.
            </p>
          </Col>
        </Section>
        <Section
          id="contact"
          title="Contact Us"
          subTit="Questions? Ideas? Feedback? Connect with us!"
        >
          
            <ContactForm product="support" />
        </Section>
      </div>
    );
  }
}
export default About;

function Circ(props) {
  return (
    <Link
      to={props.section}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
    >
      <div class="circleBase">{props.children}</div>
    </Link>
  );
}
