import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Navigation from "./Navigation";
import Notfound from "./notfound";
import Home from "./pages/Home";

import Projects from "./pages/Projects";
import About from "./pages/About";

import { Route, Switch, Link, BrowserRouter as Router } from "react-router-dom";

const routing = (
  <Router>
    <div id="body">
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/projects" component={Projects} />
        <Route path="/about" component={About} />
        <Route path="*" component={Notfound} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
