import React from "react";
import { Container, Row } from "react-bootstrap";
function Section(props) {
  return (
    <div className="section">
      <div
        className="section-content"
        id={props.id}
        style={{ margin: "50px 0 50px 0" }}
      >
        <h1 style={{ margin: "20px 0 20px 0" }} class="text-center">
          {props.title}
        </h1>
        <p style={{ margin: "20px 0 70px 0" }} class="text-center">
          {props.subTit}
        </p>
        <Container>
          <Row>{props.children}</Row>
        </Container>
      </div>
    </div>
  );
}
export default Section;
