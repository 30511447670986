import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import logo from "./content/uvgrade.png";

class Navigation extends React.Component {
  render() {
    return (
      <Navbar variant="dark" expand="md">
        <div class="container">
          <Navbar.Brand href="/">
            <img src={logo} height="55px" alt="UVGrade" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              {/*
          <NavDropdown title="About" href="/about" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Who we are</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Contact Us</NavDropdown.Item>
            <NavDropdown.Divider />
          </NavDropdown>*/}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}
export default Navigation;
