import React from "react";
class Projects extends React.Component {
  render() {
    return (
      <div class="container">
        <h1>Check back here soon</h1>
      </div>
    );
  }
}
export default Projects;
