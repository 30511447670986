import React from "react";
import Carousel from "react-bootstrap/Carousel";
import sparkhomes from "../content/home/sparkhomes.png";
class Home extends React.Component {
  render() {
    return (
      <div>
        <HTopic
          Img={sparkhomes}
          txt="Find our IT consulting at our other site. Certified in Uqiquiti Wi-Fi"
          url="https://www.sparkhomesnj.com/"
        />
      </div>
    );
  }
}
export default Home;

function HTopic(props) {
  return (
    <div class="container">
      <Carousel>
        <Carousel.Item>
          <a href={props.url}>
            <img
              className="d-block w-100 text-center"
              src={props.Img}
              alt="See our other company"
            />
          </a>

          <Carousel.Caption>
            <h3>{props.txt}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
